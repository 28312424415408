import React from 'react';

import ShowcasePage from '../../../components/ShowcasePage';

const Showcase = () => (
  <ShowcasePage
    totalItems={10}
    s3Path={`portfolio/new-born/2/`}
  />
)

export default Showcase;
